import React, { Component } from 'react';

import { withAuthentication, AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { SettingsPanel } from '../../components/SettingsPanel/SettingsPanel';
import { Page } from '../../components/Page/Page';
import { Status } from '../../components/Status/Status';

import "./UpdatePassword.css";

class UpdatePasswordBase extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                { authUser => (
                    <UpdatePasswordPanel />
                )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}

export const UpdatePassword = withAuthentication(UpdatePasswordBase);

class UpdatePasswordPanelBase extends Component {

    get initialState() {
        return {
            password: "",
            verifyPassword: "",
            error: null,
            success: null,
        }
    }
    
    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value, error: null, message: null});
    }

    onSubmit = (event) => {
        event.preventDefault();

        if (this.state.password !== this.state.verifyPassword) {
            this.setState({error: "Passwords do not match"});
            return;
        }

        // Update password
        let user = this.props.firebase.auth.currentUser;
        user.updatePassword(this.state.password)
        .then( () => {
            this.setState({success: "Password updated"});
        })
        .catch( (error) => {
            this.setState({error: error.message})
        })
    }

    render() {

        return (
            <SettingsPanel title="Update Password">
                <form onSubmit={this.onSubmit}>
                    <div>
                        <label>New Password</label>
                        <input type="password" name="password" value={this.state.password} onChange={this.onChange} required/>
                    </div>

                    <div>
                        <label>Verify New password</label>
                        <input type="password" name="verifyPassword" value={this.state.verifyPassword} onChange={this.onChange} required/>
                    </div>

                    <div id="buttons">
                        <button>Save</button>
                        <button>Cancel</button>
                    </div>

                    <Status error={this.state.error} success={this.state.success} />
                </form>
            </SettingsPanel>
        )
    }
}

export const UpdatePasswordPanel = withFirebase(UpdatePasswordPanelBase);
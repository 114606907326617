import React from 'react';
import RetinaImage from 'react-retina-image';

import "./ContentPanel.css"

import leftArrow from '../../images/left-arrow.png';
import leftArrow2x from '../../images/left-arrow@2x.png';
import rightArrow from '../../images/right-arrow.png';
import rightArrow2x from '../../images/right-arrow@2x.png';

export const ContentPanel = ({images, iconTitle, panelTitle, olderButtonHandler, newerButtonHandler, children, hideBorder}) => {
    return (
        <div id="content-panel" className={hideBorder ? "" : "drop-shadow corner-radius"}>
            <div id="header">
                <button id="left" className={newerButtonHandler ? null : "hidden"} onClick={newerButtonHandler}>
                    <RetinaImage src={[leftArrow, leftArrow2x]} alt="Left Arrow" />
                </button>
                <span id="title">
                    {images && <RetinaImage src={images} alt={iconTitle} />}
                    <span>{panelTitle}</span>
                </span>
                <button id="right" className={olderButtonHandler ? null : "hidden"} onClick={olderButtonHandler}>
                    <RetinaImage src={[rightArrow, rightArrow2x]} alt="Right Arrow" />
                </button>
            </div>
            <div id="body">
                {children}
            </div>

        </div>
    )
}
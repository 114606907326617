import React from 'react';
import { Link } from 'react-router-dom';
import { PopupMenu } from '../../components/PopupMenu/PopupMenu';

import * as ROUTES from '../../constants/routes';

import "./Footer.css";

export const Footer = () => (
    <div id="footer">
        <div id="container">
            <div id="tagline"><div>Train. Log. Improve Faster.</div></div>
            <PopupMenu trigger={<button>Create Log</button>} offsetY={-10}>
                <div className="menu">
                    <h1>Add Log Entry</h1>
                    <div className="menu-item"><Link to={ROUTES.TRAINING_ENTRY}>Training</Link></div>
                    <div className="menu-item"><Link to={ROUTES.COMPETITION_ENTRY}>Competition</Link></div>
                    <div className="menu-item"><Link to={ROUTES.PROMOTION_ENTRY}>Promotion</Link></div>
                </div>
            </PopupMenu>
        </div>
    </div>
)
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withAuthentication, AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import { LogEntryFormBase, Select, TimeDuration, TextArea } from '../../components/LogEntry/LogEntry';
import { Page } from '../../components/Page/Page';

import * as FORMATTER from "../../components/Formatter/Formatter";

import logbook74 from '../../images/logbook-74.png';
import logbook742x from '../../images/logbook-74@2x.png';

import "./TrainingEntry.css";

class TrainingEntryBase extends Component {

    render() {
        return (
            <Page>
                <ContentPanel images={[logbook74, logbook742x]} iconTitle="Logbook" panelTitle="Training">
                    <AuthUserContext.Consumer>
                        { authUser => (
                            <TrainingEntryForm authUser={authUser}/>
                        )}
                    </AuthUserContext.Consumer>
                </ContentPanel>
            </Page>
        )
    }
}

export const TrainingEntry = withAuthentication(TrainingEntryBase);

class TrainingEntryFormBase extends LogEntryFormBase {

    get initialState() {
        let now = new Date();

        return {
            version: 1,
            type: "training",
            date: FORMATTER.formatDate(now, "-"),
            time: FORMATTER.formatTime(now),
            location: "",
            trainingType: "Class",
            giOrNoGi: "Gi",
            trainingDuration: (1 * 60 * 60),
            howFelt: "Great",
            sleepDuration: (8 * 60 * 60),
            techniquesPracticed: "",
            didWell: "",
            needsWork: "",
            whatQuestions: "",
            otherNotes: "",
        }
    }

    get type() {
        return "training";
    }
    
    render() {

        let trainingDuration = FORMATTER.formatDurationToComponents(this.state.trainingDuration);
        let sleepDuration = FORMATTER.formatDurationToComponents(this.state.sleepDuration);

        return (
            <div id="training-entry-form">
                <form onSubmit={this.onSubmit}>
                    <div id="training-entry-form-row">
                        <span id="column">Date</span>
                        <span id="column"><input type="date" name="date" value={this.state.date} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Time</span>
                        <span id="column"><input type="time" name="time" value={this.state.time} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Location</span>
                        <span id="column"><input type="text" name="location" value={this.state.location} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Training Type</span>
                        <span id="column">
                            <Select name="trainingType" items={["Class", "Open Mat", "Garage"]} value={this.state.trainingType} onChange={this.onChange}/>
                        </span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Gi or No Gi</span>
                        <span id="column">
                            <Select name="giOrNoGi" items={["Gi", "No Gi"]} value={this.state.giOrNoGi} onChange={this.onChange}/>
                        </span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Training Duration</span>
                        <span id="column"><TimeDuration name="trainingDuration" duration={trainingDuration} onChange={this.onChange}/></span>
                    </div>
                    
                    <div id="training-entry-form-row">
                        <div id="column">How did you feel?</div>
                        <div id="column"><Select name="howFelt"  items={["Great", "Good", "Okay", "Bad"]} value={this.state.howFelt} onChange={this.onChange}/></div>
                    </div>

                    <div id="training-entry-form-row">
                        <div id="column">Hours of sleep last night?</div>
                        <div id="column"><TimeDuration name="sleepDuration" duration={sleepDuration} onChange={this.onChange}/></div>
                    </div>

                    <div id="training-entry-form-row">
                        <div id="text-areas">
                            <TextArea title="What techniques did you practice?" name="techniquesPracticed" value={this.state.techniquesPracticed} onChange={this.onChange}/>
                            <TextArea title="What did you do well?" name="didWell" value={this.state.didWell} onChange={this.onChange}/>
                            <TextArea title="What do you need to work on?" name="needsWork" value={this.state.needsWork} onChange={this.onChange}/>
                            <TextArea title="What questions do you have?" name="whatQuestions" value={this.state.whatQuestions} onChange={this.onChange}/>
                            <TextArea title="Other Notes" name="otherNotes" value={this.state.otherNotes} onChange={this.onChange}/>
                        </div>
                    </div>

                    <div id="buttons">
                        <button type="submit" id="save">Save</button>
                        <button type="button" id="cancel" onClick={this.onCancel}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}

export const TrainingEntryForm = withRouter(withFirebase(TrainingEntryFormBase));
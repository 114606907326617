import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withAuthentication, AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import { LogEntryFormBase, Select, TimeDuration, TextArea } from '../../components/LogEntry/LogEntry';
import { Page } from '../../components/Page/Page';

import * as FORMATTER from "../../components/Formatter/Formatter";

import logbook74 from '../../images/logbook-74.png';
import logbook742x from '../../images/logbook-74@2x.png';

import "../../pages/TrainingEntry/TrainingEntry.css";

class CompetitionEntryBase extends Component {
    render() {
        return (
            <Page>
                <ContentPanel images={[logbook74, logbook742x]} iconTitle="Logbook" panelTitle="Competition">
                    <AuthUserContext.Consumer>
                        { authUser => (
                            <CompetitionEntryForm authUser={authUser}/>
                        )}
                    </AuthUserContext.Consumer>
                </ContentPanel>
            </Page>
        )
    }
}

export const CompetitionEntry = withAuthentication(CompetitionEntryBase);

class CompetitionEntryFormBase extends LogEntryFormBase {

    get initialState() {
        return {
            version: 1,
            type: "competition",
            date: FORMATTER.formatDate( new Date(), "-"),
            location: "",
            nameOfCompetition: "",
            giOrNoGi: "",
            weightClass: "",
            numberOfMatches: 0,
            numberOfWins: 0,
            submissionWins: 0,
            submissionLosses: 0,
            awardedMedal: "",
            howDidYouFeel: "",
            sleepDuration: 0.0,
            overallFeelingsAboutExperience: "",
            whatDoYouNeedToWorkOn: "",
            whatQuestionsDoYouHave: "",
            otherNotes: ""
        }
    }

    get type() {
        return "competition";
    }
    
    render() {

        let sleepDuration = FORMATTER.formatDurationToComponents(this.state.sleepDuration);

        return (
            <div id="training-entry-form">
                <form onSubmit={this.onSubmit}>
                    <div id="training-entry-form-row">
                        <span id="column">Date</span>
                        <span id="column"><input type="date" name="date" value={this.state.date} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Location</span>
                        <span id="column"><input type="text" name="location" value={this.state.location} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Name of Competition</span>
                        <span id="column"><input type="text" name="nameOfCompetition" value={this.state.nameOfCompetition} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Gi or No Gi</span>
                        <span id="column"><Select name="giOrNoGi" items={["Gi", "No Gi"]} value={this.state.giOrNoGi} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Weight Class</span>
                        <span id="column"><input type="text" name="weightClass" value={this.state.weightClass} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Number of Matches</span>
                        <span id="column"><input type="text" name="numberOfMatches" value={this.state.numberOfMatches} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Number of Wins</span>
                        <span id="column"><input type="text" name="numberOfWins" value={this.state.numberOfWins} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Submission Wins</span>
                        <span id="column"><input type="text" name="submissionWins" value={this.state.submissionWins} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Submission Losses</span>
                        <span id="column"><input type="text" name="submissionLosses" value={this.state.submissionLosses} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Awarded Medal</span>
                        <span id="column"><Select name="awardedMedal" items={["Gold", "Silver", "Bronze", "Other"]} value={this.state.awardedMedal} onChange={this.onChange}/></span>
                    </div>
                    
                    <div id="training-entry-form-row">
                        <div id="column">How did you feel?</div>
                        <div id="column"><Select name="howFelt"  items={["Great", "Good", "Okay", "Bad"]} value={this.state.howFelt} onChange={this.onChange}/></div>
                    </div>

                    <div id="training-entry-form-row">
                        <div id="column">Hours of sleep last night?</div>
                        <div id="column"><TimeDuration name="sleepDuration" duration={sleepDuration} onChange={this.onChange}/></div>
                    </div>

                    <div id="training-entry-form-row">
                        <div id="text-areas">
                            <TextArea title="Overall feelings about experience?" name="overallFeelings" value={this.state.overallFeelings} onChange={this.onChange}/>
                            <TextArea title="What do you need to work on?" name="needsWork" value={this.state.needsWork} onChange={this.onChange}/>
                            <TextArea title="What questions do you have?" name="whatQuestions" value={this.state.whatQuestions} onChange={this.onChange}/>
                            <TextArea title="Other Notes" name="otherNotes" value={this.state.otherNotes} onChange={this.onChange}/>
                        </div>
                    </div>

                    <div id="buttons">
                        <button type="submit" id="save">Save</button>
                        <button type="button" id="cancel" onClick={this.onCancel}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}

export const CompetitionEntryForm = withRouter(withFirebase(CompetitionEntryFormBase));
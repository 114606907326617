import React, { Component } from 'react';

import { ContentPanel } from '../../components/ContentPanel/ContentPanel';

import "./SettingsPanel.css";

import settings74 from '../../images/settings-74.png';
import settings742x from '../../images/settings-74@2x.png';

export class SettingsPanel extends Component {
    render() {
        return (
            <div id="settings-panel">
                <ContentPanel images={[settings74, settings742x]} iconTitle="Settings" panelTitle={this.props.title}>
                    {this.props.children}
                </ContentPanel>
            </div>
        )
    }
}
import React, { Component } from 'react';
import Carousel from 'nuka-carousel';

import { CarouselControl } from '../../components/ResponsiveCarousel/ResponsiveCarousel';

import "./ContentCarousel.css";

export class ContentCarousel extends Component {

    static getDerivedStateFromProps(props, state) {
        if (props.location && props.location.state && props.location.key !== state.locationKey) {
            return { locationKey: props.location.key, slideIndex: props.location.state.slide };
        }
        return null;
    }
    
    constructor(props) {
        super(props);

        this.state = { locationKey: null, slideIndex: 0 };

        this.afterSlide = this.afterSlide.bind(this);
        this.totalSlides = this.totalSlides.bind(this);
        this.nextButtonPressed = this.nextButtonPressed.bind(this);
        this.backButtonPressed = this.backButtonPressed.bind(this);
    } 

    componentDidMount() {
        if (this.props.location && this.props.location.state ) {
            return { locationKey: this.props.location.key, slideIndex: this.props.location.state.slide };
        }
    }

    totalSlides() {
        let count = 0;
        React.Children.forEach(this.props.children, (child) => {
            count++;
        });
        return count;
    }

    afterSlide(slideIndex) {
        this.setState({slideIndex: slideIndex});
    }

    nextButtonPressed = () => {
        if (this.state.slideIndex < (this.totalSlides() - 1)) {
            this.setState({slideIndex: this.state.slideIndex + 1});
        }
    }

    backButtonPressed = () => {
        if (this.state.slideIndex > 0) {
            this.setState({slideIndex: this.state.slideIndex - 1});
        }
    }

    render() {
        return (
            <div id="content-carousel">
                <Carousel slideIndex={this.state.slideIndex} framePadding="0px" cellSpacing={20} withoutControls={true} slidesToShow={1} slidesToScroll={1} wrapAround={false} afterSlide={this.afterSlide}>
                    {this.props.children}
                </Carousel>
                <CarouselControl totalSlides={this.totalSlides()} slidesToShow={1} slideIndex={this.state.slideIndex} nextButtonPressed={this.nextButtonPressed} backButtonPressed={this.backButtonPressed}/>
            </div>
        )
    }
}
import React, {Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { Login, Register, ForgotPassword, VerifyEmail } from '../Account/Account';
import { Home } from '../../pages/Home/Home';
import { Search } from '../../pages/Search/Search';
import { Logbook } from '../../pages/Logbook/Logbook'
import { Training } from '../../pages/Training/Training';
import { TrainingEntry } from '../../pages/TrainingEntry/TrainingEntry';
import { Competition } from '../../pages/Competition/Competition';
import { CompetitionEntry } from '../../pages/CompetitionEntry/CompetitionEntry';
import { Promotion } from '../../pages/Promotion/Promotion';
import { PromotionEntry } from '../../pages/PromotionEntry/PromotionEntry';
import { Worldwide } from '../../pages/Worldwide/Worldwide'
import { Statistics } from '../../pages/Statistics/Statistics'
import { TotalCareer } from '../../pages/TotalCareer/TotalCareer';
import { CompetitionCareer } from '../../pages/CompetitionCareer/CompetitionCareer';
import { BeltProgress } from '../../pages/BeltProgress/BeltProgress';
import { Settings } from '../../pages/Settings/Settings';
import { AccountDetails } from '../../pages/AccountDetails/AccountDetails';
import { UpdatePassword } from '../../pages/UpdatePassword/UpdatePassword';

import * as ROUTES from '../../constants/routes';

import './App.css';

export default class App extends Component {

  componentDidMount() {
    document.title = "BJJ Logbook";
  }

  render() {
    return (  
          <Router>
            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Route exact path={ROUTES.REGISTER} component={Register} />
            <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
            <Route exact path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />

            <Route exact path={ROUTES.HOME} component={Home} />
            <Route exact path={ROUTES.SEARCH} component={Search} />

            <Route exact path={ROUTES.LOGBOOK} component={Logbook} />
            <Route exact path={ROUTES.TRAINING} component={Training} />
            <Route exact path={ROUTES.TRAINING_ENTRY} component={TrainingEntry} />
            <Route exact path={ROUTES.COMPETITION} component={Competition} />
            <Route exact path={ROUTES.COMPETITION_ENTRY} component={CompetitionEntry} />
            <Route exact path={ROUTES.PROMOTION} component={Promotion} />
            <Route exact path={ROUTES.PROMOTION_ENTRY} component={PromotionEntry} />

            <Route exact path={ROUTES.WORLDWIDE} component={Worldwide} />

            <Route exact path={ROUTES.STATISTICS} component={Statistics} />
            <Route exact path={ROUTES.TOTAL_CAREER} component={TotalCareer} />
            <Route exact path={ROUTES.COMPETITION_CAREER} component={CompetitionCareer} />
            <Route exact path={ROUTES.BELT_PROGRESS} component={BeltProgress} />

            <Route exact path={ROUTES.SETTINGS} component={Settings} />
            <Route exact path={ROUTES.ACCOUNT_DETAILS} component={AccountDetails} />
            <Route exact path={ROUTES.UPDATE_PASSWORD} component={UpdatePassword} />
          </Router>
    );
  }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../../components/Firebase/Firebase';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import Popup from "reactjs-popup";

import * as FORMATTER from "../../components/Formatter/Formatter";

import logbook74 from '../../images/logbook-74.png';
import logbook742x from '../../images/logbook-74@2x.png';

import "./LogTable.css";

class LogTableBase extends Component {

    constructor(props) {
        super(props);

        this.authUser = props.authUser;
        
        this.state = { docs: [], history: [] };

        this.olderButtonHandler = this.olderButtonHandler.bind(this);
        this.newerButtonHandler = this.newerButtonHandler.bind(this);

        this.limit = 8;
        this.unsubscribe = null;
    }

    componentDidMount() {
        let uid = this.props.authUser.uid;

        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }

        this.unsubscribe = this.props.firebase.user(uid).collection(this.props.type).orderBy("date", "desc").limit(this.limit + 1).onSnapshot( (snapshot) => {
            this.setState({docs: snapshot.docs, history: []});
        })
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    }

    newerButtonHandler() {
        let uid = this.props.authUser.uid;

        let history = this.state.history;
        let first = history.pop();

        if (!first) {
            return;
        }

        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }

        this.unsubscribe = this.props.firebase.user(uid).collection(this.props.type).orderBy("date", "desc").startAt(first).limit(this.limit + 1).onSnapshot( (snapshot) => {
            this.setState({docs: snapshot.docs, history: history});
        })
    }

    olderButtonHandler() {

        let uid = this.props.authUser.uid;
        let last = this.state.docs[this.state.docs.length - 1];
        let first = this.state.docs[0];
        
        let history = this.state.history;
        history.push(first);

        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }

        this.unsubscribe = this.props.firebase.user(uid).collection(this.props.type).orderBy("date", "desc").startAfter(last).limit(this.limit + 1).onSnapshot( (snapshot) => {
            this.setState({docs: snapshot.docs, history: history});
        })
    }

    render() {

        let newerButtonHandler = this.state.history.length > 0 ? this.newerButtonHandler : null;;
        let olderButtonHandler = null;

        // Check if there are still more items left on the server
        if (this.state.docs.length > this.limit) {
            // Remove the last item.  It was only to see if more items exist on the server
            this.state.docs.pop();

            // Enable the older button handler
            olderButtonHandler = this.olderButtonHandler;
        }

        let records = [];
        let index = 0;
        let title = this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1) ;
        var route = null;

        if (this.props.type === "training") {
            route = ROUTES.TRAINING_ENTRY;
            this.state.docs.forEach( (doc) => {
                records.push(<TrainingRow key={index} className={index % 2 === 0 ? "row-white" : "row-gray"} route={route} doc={doc}/>);
                index = index + 1;
            })
        }
        else if (this.props.type === "competition") {
            route = ROUTES.COMPETITION_ENTRY;
            this.state.docs.forEach( (doc) => {
                records.push(<CompetitionRow key={index} className={index % 2 === 0 ? "row-white" : "row-gray"} route={route} doc={doc}/>);
                index = index + 1;
            })
        }
        else if (this.props.type === "promotion") {
            route = ROUTES.PROMOTION_ENTRY;
            this.state.docs.forEach( (doc) => {
                records.push(<PromotionRow key={index} className={index % 2 === 0 ? "row-white" : "row-gray"} route={route} doc={doc}/>);
                index = index + 1;
            })
        }

        let content = <table id="log-table"><tbody>{records}</tbody></table>;
        if (records.length === 0 && this.state.history.length === 0) {
            content = <div id="no-entries"><Link to={route}>No log entries found. Create your first log now!</Link></div>
        }

        return (
            <ContentPanel images={[logbook74, logbook742x]} iconTitle="Logbook" panelTitle={title} olderButtonHandler={olderButtonHandler} newerButtonHandler={newerButtonHandler}>
                {content}
            </ContentPanel>
        )
    }
}

export var LogTable = withFirebase(LogTableBase);

class TrainingRow extends Component {

    render() {

        var { date, location, trainingType, sleepDuration, howFelt, trainingDuration } = this.props.doc.data();

        // Convert Firebase timestamp to javascript date
        date = FORMATTER.formatDate(date.toDate(), "/");

        sleepDuration = FORMATTER.formatDuration(sleepDuration);
        trainingDuration = FORMATTER.formatDuration(trainingDuration);

        return (
            <tr id="training-log-row" className="log-row">
                <td className="date">{date}</td>
                <td className="location">{location}</td>
                <td className="training-type">{trainingType}</td>
                <td className="sleep">Slept: {sleepDuration} hrs</td>

                <td className="how-felt">
                    <span>Felt:</span>
                    <span className={howFelt}>{howFelt}</span>
                </td>

                <td className="training-duration">{trainingDuration} hrs</td>
                <td><RowPopUpMenu doc={this.props.doc} route={this.props.route}/></td>
            </tr>
        )
    }
}

class CompetitionRow extends Component {

    render() {
        var { date, location, nameOfCompetition, awardedMedal } = this.props.doc.data();

        // Converte Firebase timestamp to javascript date
        date = FORMATTER.formatDate(date.toDate(), "/");

        return (
            <tr id="competition-log-row" className="log-row" >
                <td className="date">{date}</td>
                <td className="location">{location}</td>
                <td className="competition-name">{nameOfCompetition}</td>
                <td className="awarded-medal">{awardedMedal}</td>
                <td><RowPopUpMenu doc={this.props.doc} route={this.props.route}/></td>
            </tr>
        )
    }
}

class PromotionRow extends Component {

    render() {
        var { date, location, professorName, beltColor, stripeCount } = this.props.doc.data();

        // Converte Firebase timestamp to javascript date
        date = FORMATTER.formatDate(date.toDate(), "/");

        return (
            <tr id="promotion-log-row" className="log-row" >
                <td className="date">{date}</td>
                <td className="location">{location}</td>
                <td className="professor-name">{professorName}</td>
                <td className="belt">{beltColor}</td>
                <td className="stripes">{stripeCount} stripes</td>
                <td><RowPopUpMenu doc={this.props.doc} route={this.props.route}/></td>
            </tr>
        )
    }
}

class RowPopUpMenuBase extends Component {

    constructor(props) {
        super(props);

        this.deleteRow = this.deleteRow.bind(this);
    }

    deleteRow() {
        this.props.doc.ref.delete()
        .catch((error) => {
            console.log("Error", error);
        })
    }

    render() {

        var link = {
            pathname: this.props.route,
            state: { docId: this.props.doc.id, docData: this.props.doc.data() }
        }

        return (
            <div id="popup-menu">
                <Popup trigger={<button>...</button>} position="left center">{ close => (
                    <div className="menu">
                        <div id="edit" className="menu-item"><Link to={link}>Edit</Link></div>
                        <hr />
                        <div id="delete" className="menu-item">
                        <Popup trigger={<button onClick={close}>Delete</button>} modal={true}>
                                <div className="model">
                                    <div className="content">
                                        Are you sure you want to permanently delete this log entry?
                                    </div>
                                    <div className="actions">
                                        <button onClick={ (event) => {close(); this.deleteRow()}}>Delete</button>
                                        <button id="cancel" onClick={close}>Cancel</button>
                                    </div>
                            </div>
                        </Popup>
                        </div>
                    </div>

                )}</Popup>
            </div>
        );
    }
}

export var RowPopUpMenu = withFirebase(RowPopUpMenuBase);
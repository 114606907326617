import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import RetinaImage from 'react-retina-image';
import { withFirebase } from '../Firebase/Firebase';
import { withAuthentication } from '../Session/Session';
import { Status } from '../Status/Status';


import * as ROUTES from '../../constants/routes';

import './Account.css';

import logo from '../../images/bjj-logbook-logo.png';
import logo2x from '../../images/bjj-logbook-logo@2x.png';

class LoginBase extends Component {

	constructor(props) {
		super(props);

		this.state = this.initialState;

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	get initialState() {
		return {
			email: '',
			password: '',
			error: null,
			success: null
		}
	}

	get isInvalid() {
		return (
			this.state.email === '' ||
			this.state.password === ''
		);
	}

	onSubmit = (event) => {
		event.preventDefault();

		const { email, password } = this.state
		this.props.firebase.doSignInWithEmailAndPassword(email, password)
		.then(() => {
			this.setState( {...this.initialState, success: "Success"});
			this.props.history.push(ROUTES.HOME);
		})
		.catch((error) => {
			this.setState({ password: "", error: error.message });
		});
	}

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		return (
			<div className="account">
				<RetinaImage src={[logo, logo2x]} alt="BJJ Logbook Logo" />
				<h1>BJJ Logbook</h1>
				<h2>Sign-in</h2>
				<form onSubmit={this.onSubmit}>
					<div className="input-row">
						<input name="email" type="text" placeholder="Email Address" value={this.state.email} onChange={this.onChange} />
					</div>
					<div className="input-row">
						<input name="password" type="password" placeholder="Password" value={this.state.password} onChange={this.onChange} />
					</div>
					<Link to={ROUTES.FORGOT_PASSWORD} className="forgot-password">Forgot Password?</Link>
					<button type="submit" disabled={this.isInvalid}>LOG IN</button>
					<h2>Need a free account?</h2>
					<Link to={ROUTES.REGISTER}>Create account</Link>
					<Status error={this.state.error} success={this.state.success}/>
				</form>
			</div>
		)
	}
}

export const Login = withFirebase(LoginBase);

class RegisterBase extends Component {

	constructor(props) {
		super(props);

		this.state = this.initialState;

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	get initialState() {
		return {
			firstName: '',
			lastName: '',
			email: '',
			verifyEmail: '',
			password: '',
			verifyPassword: '',
			error: null,
			success: null
		}
	}

	get isInvalid() {
		return (
			this.state.email !== this.state.verifyEmail ||
			this.state.password !== this.state.verifyPassword ||
			this.state.password === '' ||
			this.state.email === '' ||
			this.state.firstName === '' ||
			this.state.lastName === ''
		);
	}

	onSubmit = (event) => {
		event.preventDefault();

		const { firstName, lastName, email, password } = this.state;

		this.props.firebase.doCreateUserWithEmailAndPassword(email, password)
		.then((authUser) => {
			// Create a user in your firestore database
			return this.props.firebase.user(authUser.user.uid).set({
				firstName, lastName, email, verified: false
			});
		})
		.then(() => {
			let displayName = firstName + ' ' + lastName;
			return this.props.firebase.doUpdateProfile({ displayName: displayName });
		})
		.then(() => {
			return this.props.firebase.doSendEmailVerification();
		})
		.then(authUser => {
			this.setState(  {...this.initialState, success: "New account created"} );
			this.props.history.push(ROUTES.LOGIN)
		}).catch(error => {
			this.setState({ error: error.message });
		})
	}

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		return (
			<div className="account">
				<RetinaImage src={[logo, logo2x]} alt="BJJ Logbook Logo" />
				<h1 >BJJ Logbook</h1>
				<h2 >Create Account</h2>
				<form onSubmit={this.onSubmit}>
					<div className="input-row">
						<input name="firstName" type="text" placeholder="First Name" value={this.state.firstName} onChange={this.onChange} />
						<input name="lastName" type="text" placeholder="Last Name" value={this.state.lastName} onChange={this.onChange} />
					</div>
					<div className="input-row">
						<input name="email" type="text" placeholder="Email Address" value={this.state.email} onChange={this.onChange} />
					</div>
					<div className="input-row">
						<input name="verifyEmail" type="text" placeholder="Verify Email Address" value={this.state.verifyEmail} onChange={this.onChange} />
					</div>
					<div className="input-row">
						<input name="password" type="password" placeholder="Password" value={this.state.password} onChange={this.onChange} />
						<input name="verifyPassword" type="password" placeholder="Verify Password" value={this.state.verifyPassword} onChange={this.onChange} />
					</div>
					<button type="submit" disabled={this.isInvalid}>CREATE MY ACCOUNT</button>
					<h2>Already have an account?</h2>
					<Link to={ROUTES.LOGIN}>Login</Link>
					<Status error={this.state.error} success={this.state.success}/>
				</form>
			</div>
		)
	}
}

export const Register = withFirebase(RegisterBase);

class ForgotPasswordBase extends Component {

	constructor(props) {
		super(props);

		this.state = this.initialState;

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	get initialState() {
		return {
			email: '',
			status: null,
			error: null,
			success: null
		}
	}

	get isInvalid() {
		return (
			this.state.email === ''
		);
	}

	onSubmit = (event) => {
		event.preventDefault();
		
		this.props.firebase.auth.sendPasswordResetEmail(this.state.email).then( () => {
		  this.setState({...this.initialState, success: "Password reset email sent"});
		}).catch( (error) => {
			this.setState({ error: error.message});
		});
	}

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {

		return (
			<div className="account">
				<RetinaImage src={[logo, logo2x]} alt="BJJ Logbook Logo" />
				<h1>BJJ Logbook</h1>
				<h2>Enter your email address</h2>
				<form onSubmit={this.onSubmit}>
					<div className="input-row">
						<input name="email" type="text" placeholder="Email Address" value={this.state.email} onChange={this.onChange} />
					</div>
					<button type="submit" disabled={this.isInvalid}>SUBMIT</button>
					<h2>Remember your password?</h2>
					<Link to={ROUTES.LOGIN}>Login</Link>
					<Status error={this.state.error} success={this.state.success}/>				</form>
			</div>
		)
	}
}

export const ForgotPassword = withFirebase(ForgotPasswordBase);

class VerifyEmailBase extends Component {

	constructor(props) {
		super(props);

		this.state = this.initialState;

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	get initialState() {
		return {
			email: '',
			error: null,
			success: null,
		}
	}

	get isInvalid() {
		return (
			!this.props.firebase || !this.props.firebase.auth || !this.props.firebase.auth.currentUser || !this.props.firebase.auth.currentUser.email
		);
	}

	onSubmit = (event) => {
		event.preventDefault();

		if (this.isInvalid) {
			return;
		}
		
		this.props.firebase.auth.currentUser.sendEmailVerification()
		.then( () => {
			this.setState( {...this.initialState, success: "Verifcation email sent"} );
		})
		.catch( (error) => {
			this.setState( {status: error.message})
		})
	}

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {

		let email = "";
		if (! this.isInvalid) {
			email = this.props.firebase.auth.currentUser.email;
		}

		return (
			<div className="account">
				<RetinaImage src={[logo, logo2x]} alt="BJJ Logbook Logo" />
				<h1>BJJ Logbook</h1>
				<h2>Enter your email address and follow the link sent to your inbox to verify your email address.</h2>
				<form onSubmit={this.onSubmit}>
					<div className="input-row">
						<input name="email" disabled={true} type="text" placeholder="Email Address" value={email} onChange={this.onChange} />
					</div>
					<button type="submit" disabled={this.isInvalid}>SUBMIT</button>
					<Link to={ROUTES.LOGIN}>Login</Link>
					<Status error={this.state.error} success={this.state.success}/>
				</form>
			</div>
		)
	}
}

export const VerifyEmail = withAuthentication(VerifyEmailBase);
import React, { Component } from 'react';

import { AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import { RowPopUpMenu } from '../../components/LogTable/LogTable';

import * as ROUTES from '../../constants/routes';
import * as FORMATTER from "../../components/Formatter/Formatter";

import logbook from '../../images/logbook-74.png';

import { Page } from '../../components/Page/Page';

import "./Search.css";

export class Search extends Component {
    render() {

        return (
            <Page>
                <AuthUserContext.Consumer>
                    { authUser => (
                        <SearchTable authUser={authUser} location={this.props.location}/>
                    )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}

class SearchTableBase extends Component {

    constructor(props) {
        super(props);

        this.state = { docs: [], page: 0 };
        this.limit = 8;

        this.getKeyText = this.getKeyText.bind(this);
        this.newerButtonHandler = this.newerButtonHandler.bind(this);
        this.olderButtonHandler = this.olderButtonHandler.bind(this);
    }

    componentDidMount() {

        let uid = this.props.authUser.uid;
        let promises = []

        promises.push( this.props.firebase.user(uid).collection("training").orderBy("date", "desc").get() );
        promises.push( this.props.firebase.user(uid).collection("competition").orderBy("date", "desc").get() );
        promises.push( this.props.firebase.user(uid).collection("promotion").orderBy("date", "desc").get() );

        Promise.all( promises )
        .then( ([snapshotTraining, snapshotCompetition, snapshotPromotion]) => {
            let docs = [];

            docs = docs.concat( snapshotTraining.docs, snapshotCompetition.docs, snapshotPromotion.docs );

            // Sort all docs by date
            docs = docs.sort( (a, b) => {
                let aDate = a.data().date.toDate();
                let bDate = b.data().date.toDate();

                return aDate > bDate;
            })

            return docs;
        })
        .then( (docs) => {
            this.setState({docs: docs});
        })
        .catch( (error) => {
            console.log(error);
        })
    }

    getKeyText = (key) => {
        let keyText = {
            date: "Date",
            location: "Location", 
            trainingType: "Training Type",
            giOrNoGi: "Gi or No Gi",
            trainingDuration: "Training Duration",
            howFelt: "How did you feel?",
            sleepDuration: "Sleep Duration",
            techniquesPracticed: "What techniques did you practice?",
            didWell: "What did you do well?",
            needsWork: "What do you need to work on?",
            whatQuestions: "What questions do you have?",
            otherNotes: "Other Notes",

            nameOfCompetition: "Name of Competition",
            weightClass: "Weight Class",
            numberOfMatches: "Number of Matches",
            numberOfWins: "Number of Wins",
            submissionWins: "Submmission Wins",
            submissionLosses: "Submission Losses",
            awardedMedal: "Awarded Medal",
            howDidYouFeel: "How did you feel?",
            overallFeelingsAboutExperience: "Overall feelings about experience?",
            whatDoYouNeedToWorkOn: "What do you need to work on?",
            whatQuestionsDoYouHave: "What questions do you have?",

            overallFeelings: "Overall Feelings", // Is this still used?

            professorName: "Name of Professor",
            beltColor: "Belt Color",
            stripeCount: "Number of Stripes",
            professorQuote: "What did your professor say?",
        }

        if (key in keyText) {
            return keyText[key];            
        }
        else {
            return key;
        }
    }

    newerButtonHandler = () => {
        this.setState({page: this.state.page - this.limit});
    }

    olderButtonHandler = () => {
        this.setState({page: this.state.page + this.limit});
    }

    render() {

        let matches = [];
        if (this.props.location && this.props.location.state && this.props.location.state.search) {
            this.state.docs.forEach( (doc) => {
                let data = doc.data();

                // eslint-disable-next-line no-unused-vars
                for (let key in data) {
                    let value = data[key];
                    let keyText = this.getKeyText(key);

                    // Convert all values to strings
                    if (typeof value === "object" && value.toString().startsWith("Timestamp")) {
                        let date = value.toDate();
                        value = FORMATTER.formatDate(date, "/") + " " + FORMATTER.formatTime(date);
                    }
                    else if (typeof value == "number") {
                        value = value.toString();
                    }

                    if (typeof value !== "string") {
                        return;
                    }

                    // Perform search
                    if (value.toLowerCase().includes(this.props.location.state.search.toLowerCase())) {
                        matches.push({search: this.props.location.state.search, doc: doc, key: keyText, value: value});
                    }
                    else if (keyText.toLowerCase().includes(this.props.location.state.search.toLowerCase())) {
                        if (value !== "") {
                            matches.push({search: this.props.location.state.search, doc: doc, key: keyText, value: value});
                        }
                    }
                }
            });    
        }

        matches = matches.slice(this.state.page, this.state.page + this.limit);

        // Display newer and older button handler as needed
        let newerButtonHandler = (this.state.page > 0) ? this.newerButtonHandler : null;
        let olderButtonHandler = (this.state.page + this.limit < this.state.docs.length) ? this.olderButtonHandler : null;

        let records = [];
        let index = 0;

        matches.forEach( (match) => {
            records.push(<SearchRow key={index} className={index % 2 === 0 ? "row-white" : "row-gray"} match={match}/>);
            index = index + 1;
        })

        let content = <table id="log-table"><tbody>{records}</tbody></table>;
        if (records.length === 0) {
            content = <div id="no-entries">No log entries match your search term</div>        
        }

        return (
            <div id="search-table">
                <ContentPanel icon={logbook} iconTitle="Logbook" panelTitle="Search" olderButtonHandler={olderButtonHandler} newerButtonHandler={newerButtonHandler}>
                    {content}
                </ContentPanel>
            </div>
        )
    }
}

const SearchTable = withFirebase(SearchTableBase);

class SearchRow extends Component {

    render() {

        let date = this.props.match.doc.data().date;
        let type = this.props.match.doc.data().type;
        let route = null;

        if (type === "training") {
            route = ROUTES.TRAINING_ENTRY;
        }
        else if (type === "competition") {
            route = ROUTES.COMPETITION_ENTRY;
        }
        else {
            route = ROUTES.PROMOTION_ENTRY;
        }

        // Convert Firebase timestamp to javascript date
        date = FORMATTER.formatDate(date.toDate(), "/");

        return (
            <tr id="search-row" className="log-row">
                <td className="date">{date}</td>
                <td className="type">{type.charAt(0).toUpperCase() + type.slice(1)}</td>
                <td className="key">{this.props.match.key}</td>
                <td className="match">{this.props.match.value}</td>
                <td className="menu"><RowPopUpMenu doc={this.props.match.doc} route={route}/></td>
            </tr>
        )
    }
}

import React, { Component } from 'react';

import { AuthUserContext } from '../../components/Session/Session';
import { Page } from '../../components/Page/Page';
import { ContentCarousel } from '../../components/ContentCarousel/ContentCarousel';

import { TotalCareerPanel } from '../../pages/TotalCareer/TotalCareer';
import { CompetitionCareerPanel } from '../../pages/CompetitionCareer/CompetitionCareer';
import { BeltProgressPanel } from '../../pages/BeltProgress/BeltProgress';

import "./Statistics.css";

export class Statistics extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                    { authUser => (
                        <div id="statistics">
                            <ContentCarousel location={this.props.location}>
                                <div id="panel"><TotalCareerPanel authUser={authUser}/></div>
                                <div id="panel"><CompetitionCareerPanel authUser={authUser}/></div>
                                <div id="panel"><BeltProgressPanel authUser={authUser}/></div>
                            </ContentCarousel>
                        </div>
                    )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}
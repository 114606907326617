import React from 'react';
import RetinaImage from 'react-retina-image';

import { withFirebase } from '../../components/Firebase/Firebase';
import { ResponsiveCarousel } from '../../components/ResponsiveCarousel/ResponsiveCarousel';
import { Belt } from '../../components/Belt/Belt';

import logo from '../../images/bjj-logbook-logo.png'
import logo2x from '../../images/bjj-logbook-logo@2x.png'

import "./HeaderStats.css"

class HeaderStatsBase extends React.Component {

    get beltColors() {
        return [
            "White",
            "Grey/White",
            "Grey",
            "Grey/Black",
            "Yellow/White",
            "Yellow",
            "Yellow/Black",
            "Orange/White",
            "Orange",
            "Orange/Black",
            "Green/White",
            "Green",
            "Green/Black",
            "Blue",
            "Purple",
            "Brown",
            "Black",
        ];
    }
    constructor(props) {
        super(props);

        this.state = {
            currentBeltColor: "White",
            currentBeltStripes: 0,
            logStreak: 0,
            logPoints: 0,
            totalTrainingThisWeek: 0.0,
            totalTrainingThisMonth: 0.0,
            totalTrainingThisYear: 0.0,
         };

        this.calculateCurrentBelt = this.calculateCurrentBelt.bind(this);
        this.calculateCurrentLogStats = this.calculateCurrentLogStats.bind(this);
        this.calculateLogLevel = this.calculateLogLevel.bind(this);
        this.calculateLogStreak = this.calculateLogStreak.bind(this);
    }

    componentDidMount() {
        this.calculateCurrentBelt()
        .then( (currentBelt) => {
            this.setState(currentBelt);
        })
        .catch( (error) => {
            console.log("Error", error);
        })

        this.calculateCurrentLogStats()
        .then( (logStats) => {
            this.setState(logStats);
        })
        .catch( (error) => {
            console.log("Error", error);
        })
    }

    calculateCurrentBelt() {
        return this.props.firebase.user(this.props.authUser.uid).collection("promotion").get()
        .then( (snapshot) => {

            let currentBeltColorIndex = 0;
            let currentStripes = 0;

            snapshot.docs.forEach( (doc) => {
                let data = doc.data();

                let beltColor = data['beltColor'];
                let beltColorIndex = this.beltColors.indexOf(beltColor);
                let stripeCount = data['stripeCount'];

                if (beltColorIndex > currentBeltColorIndex) {
                    currentBeltColorIndex = beltColorIndex;
                    currentStripes = stripeCount;
                }
                else if (beltColorIndex === currentBeltColorIndex && stripeCount > currentStripes) {
                    currentStripes = stripeCount;
                }
            });

            let currentBeltColor = this.beltColors[currentBeltColorIndex];
            let currentBeltStripes = currentStripes;

            return {currentBeltColor, currentBeltStripes};
        });
    }

    calculateCurrentLogStats() {
        let promises = []

        promises.push( this.props.firebase.user(this.props.authUser.uid).collection("training").get() );
        promises.push( this.props.firebase.user(this.props.authUser.uid).collection("competition").get() );
        promises.push( this.props.firebase.user(this.props.authUser.uid).collection("promotion").get() );

        return Promise.all(promises)
        .then( (snapshots) => {
            let logEntryCount = 0;
            let logDates = [];

            snapshots.forEach( (snapshot) => {
                snapshot.forEach( (doc) => {
                    logEntryCount++;
                    logDates.push( doc.data()['date'].toDate() );
                })    
            })

            let logPoints = logEntryCount * 10;
            let logLevel = this.calculateLogLevel(logPoints);
            let logStreak = this.calculateLogStreak(logDates);

            // Calculate total training hours for this week, this month, and this year
            let now = new Date();
            let totalTrainingThisYear = 0.0;
            let totalTrainingThisMonth = 0.0;
            let totalTrainingThisWeek = 0.0;

            // Get start of current week
            // https://stackoverflow.com/questions/5210376/how-to-get-first-and-last-day-of-the-week-in-javascript/26922029
            let weekStart = new Date( now.setDate((now.getDate() - now.getDay())) );
            weekStart.setHours(0);
            weekStart.setMinutes(0);
            weekStart.setSeconds(0);
            weekStart.setMilliseconds(0);

            // Enumerate training and calculate training hours this year, month, and week
            snapshots[0].forEach( (doc) => {
                let data = doc.data();
                let date = data['date'].toDate();
                let duration = data['trainingDuration'];
                
                if (now.getFullYear() === date.getFullYear()) {
                    totalTrainingThisYear += duration;
                    if (now.getMonth() === date.getMonth()) {
                        totalTrainingThisMonth += duration;
                        if (date >= weekStart) {
                            totalTrainingThisWeek += duration;
                        }
                    }
                }
            });

            // Convert from seconds to hours
            let secondsPerHour = 60 * 60;
            totalTrainingThisYear /= secondsPerHour;
            totalTrainingThisMonth /= secondsPerHour;
            totalTrainingThisWeek /= secondsPerHour;

            return {logPoints, logLevel, logStreak, totalTrainingThisYear, totalTrainingThisMonth, totalTrainingThisWeek};
        });
    }

    calculateLogStreak(logDates) {
        
        let today = Date.now();
        let last = today;
        let secondsPerWeek = 7 * 24 * 60 * 60 * 1000;

        let sortedDates = logDates.sort( (a, b) => {
            return b - a;
        });

        // eslint-disable-next-line no-unused-vars
        for (let date of sortedDates) {
            let duration = last - date;
            if ( duration > secondsPerWeek ) {
                break;
            }
            last = date;
        }

        let logStreak = ((today - last) / secondsPerWeek).toFixed(0);
        return logStreak;
    }

    calculateLogLevel(points) {
        if (!points || points <= 250) {
            return "Beginner";
        }
        else if (points <= 500) {
            return "Intermediate";
        }
        else if (points <= 750) {
            return "Advanced";
        }
        else if (points <= 1000) {
            return "Expert";
        }
        else if (points <= 1500) {
            return "Pro";
        }
        else if (points <= 2000) {
            return "Old Pro";
        }
        else {
            return "Master";
        }
    }

    render() {

        let userName = this.props.authUser.firstName + " " + this.props.authUser.lastName;
        let loggingSince = this.props.authUser.accountCreationDate.getFullYear();
    
        return (
            <div id="header-stats">
                <ResponsiveCarousel>
                    <UserInfoPanel userName={userName} loggingSince={loggingSince} />
                    <HeaderBeltPanel authUser={this.props.authUser} beltColor={this.state.currentBeltColor} beltStripes={this.state.currentBeltStripes}/>
                    <TotalTrainingPanel totalTrainingThisYear={this.state.totalTrainingThisYear} totalTrainingThisMonth={this.state.totalTrainingThisMonth} totalTrainingThisWeek={this.state.totalTrainingThisWeek}/>
                    <HeaderStatPanel title={this.state.logLevel} body={this.state.logPoints}/>
                    <HeaderStatPanel title="Log Streak" body={this.state.logStreak + " weeks"}/>
                </ResponsiveCarousel>
            </div>
        )
    }
}

export const HeaderStats = withFirebase(HeaderStatsBase);

const UserInfoPanel = ({userName, loggingSince}) => {
    return (
        <div id="user-info-panel" className="column">
            <div id="left-column">
                <RetinaImage id="logo" src={[logo, logo2x]} alt="BJJ Logbook Logo"/>
            </div>
            <div id="right-column">
                <div id="user-name">{userName}</div>
                <div>Logging since {loggingSince}</div>
            </div>
        </div>
    );
}

const HeaderBeltPanel = ({beltColor, beltStripes}) => {
    return (
        <div id="header-belt-panel" className="column drop-shadow corner-radius">
            <Belt beltColor={beltColor} beltStripes={beltStripes} beltYears={null}/>
        </div>
    )
}

const TotalTrainingPanel = (props) => {

    return (
        <div id="total-training-panel" className="column drop-shadow corner-radius">
            <div id="title-row">
                <div id="title">Total Training Hours</div>
            </div>
            <div id="duration-value-row">
                <div className="duration-value">{props.totalTrainingThisWeek}</div>
                <div className="duration-value">{props.totalTrainingThisMonth}</div>
                <div className="duration-value">{props.totalTrainingThisYear}</div>
            </div>
            <div id="duration-title-row">
                <div className="duration-title">This Week</div>
                <div className="duration-title">This Month</div>
                <div className="duration-title">This Year</div>
            </div>
        </div>
    )
}


const HeaderStatPanel = ({title, body}) => {
    return (
        <div id="header-stat-panel" className="column drop-shadow corner-radius">
            <div id="title-row"><div id="title">{title}</div></div>
            <div id="body-row"><div id="body">{body}</div></div>
        </div>
    )
}
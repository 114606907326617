import React, { Component } from 'react';
import * as FORMATTER from "../../components/Formatter/Formatter";

import "./LogEntry.css"

export class LogEntryFormBase extends Component {

    get initialState() {
        throw new Error('You have to implement the method initialState!');
    }

    get type() {
        throw new Error('You have to implement the method type!');
    }

    constructor(props) {
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

        this.fetchDocumentInState = this.fetchDocumentInState.bind(this);
        this.fetchMostRecentDocument = this.fetchMostRecentDocument.bind(this);
        this.parseDefaultsFromData = this.parseDefaultsFromData.bind(this);
        this.updateExistingDoc = this.updateExistingDoc.bind(this);
        this.createNewDoc = this.createNewDoc.bind(this);

        this.state = this.initialState;
        this.docId = null;
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.docId ) {
            this.fetchDocumentInState();
        }
        else {
            this.fetchMostRecentDocument();
        }
    }

    fetchDocumentInState = () => {
        let uid = this.props.authUser.uid;
        this.docId = this.props.location.state.docId;

        if (this.docId) {
            // Retrieve specified record
            this.props.firebase.user(uid).collection(this.type).doc(this.docId).get()
            .then( (doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    data["date"] = FORMATTER.formatDate( doc.data().date.toDate(), "-");
                    data["time"] = FORMATTER.formatTime( doc.data().date.toDate());
                    this.setState( data );
                }
                else {
                    this.setState( this.initialState );
                }
            })
            .catch( (error) => {
                console.log("Error: ", error);
            })
        }
    }

    fetchMostRecentDocument = () => {
        let uid = this.props.authUser.uid;

        // Retrieve specified record
        this.props.firebase.user(uid).collection(this.type).orderBy('date', 'desc').limit(1).get()
        .then( (snapshot) => {
            if (snapshot.docs.length === 0) {
                this.setState( this.initialState );
            }
            else {
                let doc = snapshot.docs[0];
                let data = doc.data();
                let defaults = this.parseDefaultsFromData(data);
                this.setState( defaults );
            }
        })
        .catch( (error) => {
            console.log("Error: ", error);
        })
    }

    parseDefaultsFromData(data) {
        let defaults = this.initialState;

        if (this.type === 'training') {
            defaults = {
                time: FORMATTER.formatTime(data.date.toDate()),
                location: data.location,
                trainingType: data.trainingType,
                giOrNoGi: data.giOrNoGi,
                trainingDuration: data.trainingDuration,
                sleepDuration: data.sleepDuration,
            }
        }
        else if (this.type === 'competition') {
            defaults = {
                location: data.location,
                giOrNoGi: data.giOrNoGi,
                weightClass: data.weightClass,
                trainingDuration: data.trainingDuration,
                sleepDuration: data.sleepDuration,
            }
        }
        else if (this.type === 'promotion') {
            defaults = {
                location: data.location,
                professorName: data.professorName,
                beltColor: data.beltColor,
                stripeCount: data.stripeCount
            }
        }

        return defaults;
    }

    onCancel = (event) => {
        this.props.history.goBack();
    }

    onSubmit = (event) => {
        event.preventDefault();

        //
        // Convert date from a string back to a date object
        //

        // Create a deep copy
        let data = JSON.parse(JSON.stringify(this.state));

        let [year, month, day] = this.state.date.split('-').map( (component) => {
            return parseInt(component, 10);
        })

        // Create the date
        let date = new Date();
        date.setFullYear(year, month-1, day);
        
        // If time exists, use it to set the dates hour
        if (this.state.time) {
            let [hour, minute] = this.state.time.split(':').map( (component) => {
                return parseInt(component, 10);
            })
            
            date.setHours(hour, minute, 0, 0);
        }

        // Assign the new date w/ time and remove time from the data.
        data['date'] = date;
        delete data['time'];

        // Update or create the new document accordingly
        if (this.docId) {
            this.updateExistingDoc(data);
        }
        else {
            this.createNewDoc(data);
        }
    }

    updateExistingDoc = (data) => {
        let uid = this.props.authUser.uid;

        this.props.firebase.user(uid).collection(this.type).doc(this.docId).set(data)
        .then( (docRef) => {
            this.props.history.goBack();
        })
        .catch( (error) => {
            console.error("Error: ", error);
        })
    }

    createNewDoc = (data) => {
        let uid = this.props.authUser.uid;

        this.props.firebase.user(uid).collection(this.type).add(data)
        .then( (docRef) => {
            this.props.history.push('/' + this.type);
        })
        .catch( (error) => {
            console.error("Error: ", error);
        })
    }

    onChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value})
    }
}

export const Select = ({name, title, items, value, onChange}) => {
    let options = []

    if (items) {
        for (let i = 0; i < items.length; i++) {
            options.push(<option key={i}>{items[i]}</option>);
        }
    }

    return (
        <select name={name} value={value} onChange={onChange}>       
            {options}
        </select>
    )
}

export class TimeDuration extends Component {

    constructor(props) {
        super(props);

        this.hour = this.props.duration.hour;
        this.minute = this.props.duration.minute;

        this.onChange = this.onChange.bind(this);
    }

    onChange = (event) => {
        // Intercept event to parse out hour and minute and then
        // pass it up to parent using the name and onChange handler
        // that was passed down from the parent.
        const { name, onChange } = this.props;
    
        if (event.target.name === "hour") {
            this.hour = parseInt(event.target.value, 10);
        }
        else if (event.target.name === "minute") {
            this.minute = parseInt(event.target.value, 10);
        }

        let duration = (this.hour * 60 * 60 + this.minute * 60);

        onChange({target: {name, value: duration}});
    }

    render() {

        let hours = [...Array(24).keys()]
        let minutes = [0, 15, 30, 45]

        let hOptions = hours.map(hour => {
            return <option key={hour} value={hour}>{hour} hours</option>
        })


        let mOptions = minutes.map(minute => {
            return <option key={minute} value={minute}>{minute} minutes</option>
        })

        return (
            <div id="time-duration">
                <select name="hour" value={this.props.duration.hour} onChange={this.onChange}>{hOptions}</select>
                <select name="minute" value={this.props.duration.minute} onChange={this.onChange}>{mOptions}</select>
            </div>
        )
    }
}

export const TextArea = ({title, name, value, onChange}) => {
    return (
        <div id="text-area">
            <span>{title}</span>
            <textarea name={name} value={value} onChange={onChange} />
        </div>
    )
}
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withAuthentication, AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import { LogEntryFormBase, Select, TextArea } from '../../components/LogEntry/LogEntry';
import { Page } from '../../components/Page/Page';

import * as FORMATTER from "../../components/Formatter/Formatter";

import logbook74 from '../../images/logbook-74.png';
import logbook742x from '../../images/logbook-74@2x.png';


import "../../pages/TrainingEntry/TrainingEntry.css";

class PromotionEntryBase extends Component {
    render() {
        return (
            <Page>
                <ContentPanel images={[logbook74, logbook742x]} iconTitle="Logbook" panelTitle="Promotion">
                    <AuthUserContext.Consumer>
                        { authUser => (
                            <CompetitionEntryForm authUser={authUser}/>
                        )}
                    </AuthUserContext.Consumer>
                </ContentPanel>
            </Page>
        )
    }
}

export const PromotionEntry = withAuthentication(PromotionEntryBase);

class CompetitionEntryFormBase extends LogEntryFormBase {

    get initialState() {
        return {
            // CHANGED
            version: 1,
            type: "promotion",
            date: FORMATTER.formatDate(new Date(), "-"),
            location: "",
            professorName: "",
            beltColor: "White",
            stripeCount: 0,
            professorQuote: "",
            otherNotes: ""
        }
    }

    get type() {
        return "promotion";
    }

    render() {

        let beltColors = [
            "White",
            "Grey/White",
            "Grey",
            "Grey/Black",
            "Yellow/White",
            "Yellow",
            "Yellow/Black",
            "Orange/White",
            "Orange",
            "Orange/Black",
            "Green/White",
            "Green",
            "Green/Black",
            "Blue",
            "Purple",
            "Brown",
            "Black",
        ];

        return (
            <div id="training-entry-form">
                <form onSubmit={this.onSubmit}>
                    <div id="training-entry-form-row">
                        <span id="column">Date</span>
                        <span id="column"><input type="date" name="date" value={this.state.date} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Location</span>
                        <span id="column"><input type="text" name="location" value={this.state.location} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Name of Professor</span>
                        <span id="column"><input type="text" name="professorName" value={this.state.professorName} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Belt Color</span>
                        <span id="column"><Select name="beltColor" items={beltColors} value={this.state.beltColor} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <span id="column">Number of Stripes</span>
                        <span id="column"><Select name="stripeCount" items={["0", "1", "2", "3", "4"]} value={this.state.stripeCount} onChange={this.onChange}/></span>
                    </div>

                    <div id="training-entry-form-row">
                        <div id="text-areas">
                            <TextArea title="What did your professor say?" name="professorQuote" value={this.state.professorQuote} onChange={this.onChange}/>
                            <TextArea title="Other Notes" name="otherNotes" value={this.state.otherNotes} onChange={this.onChange}/>
                        </div>
                    </div>

                    <div id="buttons">
                        <button type="submit" id="save">Save</button>
                        <button type="button" id="cancel" onClick={this.onCancel}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}

export const CompetitionEntryForm = withRouter(withFirebase(CompetitionEntryFormBase));
import React, { Component } from 'react';

import { withAuthentication, AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import { Belt } from '../../components/Belt/Belt';

import { Page } from '../../components/Page/Page';

import stats74 from '../../images/stats-74.png';
import stats742x from '../../images/stats-74@2x.png';

import "./BeltProgress.css";

export class BeltProgressBase extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                { authUser => (
                    <BeltProgressPanel authUser={authUser} />
                )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}

export const BeltProgress = withAuthentication(BeltProgressBase);

class BeltProgressPanelBase extends Component {

    constructor(props) {
        super(props);

        this.state = { awardedBelts: [] }

        this.calculateAwardedBelts = this.calculateAwardedBelts.bind(this);
    }

    componentDidMount() {
        let uid = this.props.authUser.uid;
        this.props.firebase.user(uid).collection("promotion").orderBy("date", "desc").get().then( (snapshot) => {
            let awardedBelts = this.calculateAwardedBelts(snapshot.docs);
            this.setState({awardedBelts: awardedBelts});
        })
        .catch( (error) => {
            console.log("Error ", error);
        })
    }

    calculateAwardedBelts = (docs) => {
        // Calculate the promotion date and number or stripes for each promotion
        let beltPromotionDates = {};
        let beltStripes = {}

        docs.forEach( (doc) => {
            let date = doc.data()["date"].toDate();
            let stripeCount = doc.data()["stripeCount"];
            let beltColor = doc.data()["beltColor"];

            if (!beltPromotionDates[beltColor] || date < beltPromotionDates[beltColor]) {
                beltPromotionDates[beltColor] = date;
            }

            if (!beltStripes[beltColor] || stripeCount > beltStripes[beltColor]) {
                beltStripes[beltColor] = stripeCount;
            }
        });

        // Combine data into a single array
        let awardedBelts = [];
        let endDate = new Date();

        let isFirst = true;
        Object.keys(beltPromotionDates).forEach( (beltColor) => {
            // https://www.toptal.com/software/definitive-guide-to-datetime-manipulation
            
            // Get number of stripes for belt
            let beltStripe = isFirst ? beltStripes[beltColor] : 4;
            isFirst = false;

            // Get date duration
            let promotionDate = beltPromotionDates[beltColor];
            let dateDelta = new Date(endDate - promotionDate);
            let years = dateDelta.getUTCFullYear() - 1970;
            let months = dateDelta.getUTCMonth() / 12;
            let beltDuration = (years + months).toFixed(2);

            awardedBelts.push({beltColor, beltDuration, beltStripe});
            endDate = promotionDate;            
        })

        return awardedBelts;
    }

    render() {
        let belts = [];

        this.state.awardedBelts.forEach( (belt, index) => {
            let {beltColor, beltDuration, beltStripe} = belt;
            belts.push(<Belt key={index} beltColor={beltColor} beltStripes={beltStripe} beltYears={beltDuration} />);
        })

        return (
            <ContentPanel images={[stats74, stats742x]} iconTitle="Stats" panelTitle="Belt Progress">
                <div id="belt-progress-container">
                    {belts}
                </div>
            </ContentPanel>
        )
    }
}

export const BeltProgressPanel = withFirebase(BeltProgressPanelBase);
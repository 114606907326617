import React, { Component } from 'react';

import { AuthUserContext } from '../../components/Session/Session';
import { LogTable } from '../../components/LogTable/LogTable';

import { Page } from '../../components/Page/Page';

import "./Competition.css";

export class Competition extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                    { authUser => (
                        <LogTable authUser={authUser} type="competition"/>
                    )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}
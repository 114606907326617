import React from 'react';
import Popup from "reactjs-popup";

// https://react-popup.elazizi.com/react-modal/

import "./PopupMenu.css";

export const PopupMenu = ({trigger, children}) => {
    return (
        <div id="popup-menu">
            <Popup trigger={trigger} position="top center">
                <div className="menu">
                    {children}
                </div>
            </Popup>
        </div>
    )
}
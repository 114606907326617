import React, { Component } from 'react';

import { AuthUserContext } from '../../components/Session/Session';
import { Page } from '../../components/Page/Page';
import { ContentCarousel } from '../../components/ContentCarousel/ContentCarousel';
import { LogTable } from '../../components/LogTable/LogTable';

import "./Logbook.css";

export class Logbook extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                    { authUser => (
                        <div id="logbook">
                            <ContentCarousel location={this.props.location}>
                                <div id="panel"><LogTable authUser={authUser} type="training"/></div>
                                <div id="panel"><LogTable authUser={authUser} type="competition"/></div>
                                <div id="panel"><LogTable authUser={authUser} type="promotion"/></div>
                            </ContentCarousel>
                        </div>
                    )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}
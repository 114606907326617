import React, { Component } from 'react';

import { withAuthentication, AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import { StatBox } from "../../components/StatBox/StatBox"
import { Page } from '../../components/Page/Page';

import stats74 from '../../images/stats-74.png';
import stats742x from '../../images/stats-74@2x.png';

import "./TotalCareer.css";

class TotalCareerBase extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                { authUser => (
                    <TotalCareerPanel authUser={authUser} />
                )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}

export const TotalCareer = withAuthentication(TotalCareerBase);

class TotalCareerPanelBase extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState;

        this.calculateTrainingStats = this.calculateTrainingStats.bind(this);
    }

    get initialState() {
        return {
            yearsOfTraining: 0,
            totalHoursTrained: 0,
            totalTrainingSessions: 0,
            longestTrainingBreak: 0,
            avgTrainingSessionsPerWeek: 0,
            avgTrainingHoursPerWeek: 0,
            avgTrainingHoursPerMonth: 0,
            avgTrainingHoursPerYear: 0,
        };
    }

    componentDidMount() {

        this.calculateTrainingStats()
        .then( (data) => {
            // Initialize stats
            let stats = this.initialState;
            
            // Calculate average training hours per week, month, and year
            var SECS_PER_WEEK = 60 * 60 * 24 * 7;
            var SECS_PER_YEAR = SECS_PER_WEEK * 52;
            var SECS_PER_MONTH = SECS_PER_YEAR / 12;

            // Calculate years of training based on first log date
            let now = new Date();
            let firstLogDate = data['firstLogDate'].toDate();
            let secondsElapsedSinceFirstLogDate = (now.getTime() - firstLogDate.getTime()) / 1000;
            stats['yearsOfTraining'] = (secondsElapsedSinceFirstLogDate / SECS_PER_YEAR).toFixed(2);

            // Calculate years of training
            let firstTrainingDate = data['firstTrainingDate'].toDate();
            let secondsElapsedSinceFirstTrainingDate = (now.getTime() - firstTrainingDate.getTime()) / 1000;
            let totalWeeksTraining = (secondsElapsedSinceFirstTrainingDate / SECS_PER_WEEK).toFixed(2);
            let totalMonthsTraining = (secondsElapsedSinceFirstTrainingDate / SECS_PER_MONTH).toFixed(2);
            let totalYearsTraining = (secondsElapsedSinceFirstTrainingDate / SECS_PER_YEAR).toFixed(2);

            let totalTrainingHours = data['totalTrainingHours'];
            stats['totalHoursTrained'] = totalTrainingHours;

            let totalTrainingSessions =  data['totalTrainingSessions'];
            stats['totalTrainingSessions'] = totalTrainingSessions;
            stats['longestTrainingBreak'] = (data['maxDaysBetweenSessions'] / 7).toFixed(2);

            stats['avgTrainingSessionsPerWeek'] = (totalTrainingSessions / totalWeeksTraining).toFixed(2);
            stats['avgTrainingHoursPerWeek'] = (totalTrainingHours / totalWeeksTraining).toFixed(2);
            stats['avgTrainingHoursPerMonth'] = (totalTrainingHours / totalMonthsTraining).toFixed(2);
            stats['avgTrainingHoursPerYear'] = (totalTrainingHours / totalYearsTraining).toFixed(2);
    
            this.setState( stats );
        })
        .catch( (error) => {
            console.log("Error", error);
        })
    }

    calculateTrainingStats = () => {

        // Get all training documents and first competiton and promotion document to calculate stats    
        let promises = []
        promises.push( this.props.firebase.user(this.props.authUser.uid).collection('training').orderBy('date').get() );
        promises.push( this.props.firebase.user(this.props.authUser.uid).collection('competition').orderBy('date').limit(1).get() );
        promises.push( this.props.firebase.user(this.props.authUser.uid).collection('promotion').orderBy('date').limit(1).get() );

        return Promise.all(promises)
        .then( ([trainingSnapshot, competitionSnapshot, promotionSnapshot]) => {
    
            let version = 1;
            let firstTrainingDate = null;
            let mostRecentTrainingDate = null;
            let totalSecondsTrained = 0;
            let totalTrainingSessions = 0;
            let maxDaysBetweenSessions = 0;
    
            trainingSnapshot.docs.forEach( (doc) => {
                let data = doc.data();
    
                // Store first training date
                if (!firstTrainingDate) {
                    firstTrainingDate = data['date'];
                }
    
                // Calculate days since last training date
                if (!mostRecentTrainingDate) {
                    mostRecentTrainingDate = data['date'].toDate();
                }
                else {
                    let millisecondsPerDay = 24 * 60 * 60 * 1000;
                    let thisTrainingDate = data['date'].toDate();
                    let daysBetweenSessions = parseInt((thisTrainingDate - mostRecentTrainingDate) / millisecondsPerDay);
                    if (daysBetweenSessions > maxDaysBetweenSessions) {
                        maxDaysBetweenSessions = daysBetweenSessions;
                    }

                    mostRecentTrainingDate = thisTrainingDate;
                }
    
                // Increment training duration
                let trainingDuration = data['trainingDuration'];
                totalSecondsTrained += trainingDuration;
    
                // Increment training sessions
                totalTrainingSessions += 1;
            });
    
            // Calculate total hours trained
            let totalTrainingHours = (totalSecondsTrained / 3600.0);

            // Check competion and promotion snapshots for date before firstTrainingDate
            let firstLogDate = firstTrainingDate;
            [competitionSnapshot, promotionSnapshot].forEach( (snapshot) => {
                snapshot.forEach( (doc) => {
                    let date = doc.data().date;
                    if (date < firstLogDate) {
                        firstLogDate = date;
                    }
                })
            })
            
            // Create and return stats
            let stats = {version, firstLogDate, firstTrainingDate, mostRecentTrainingDate, totalTrainingHours, totalTrainingSessions, maxDaysBetweenSessions};
            return stats;
        });
    }  

    render() {
        return (
            <div id="career-panel">
                <ContentPanel images={[stats74, stats742x]} iconTitle="Stats" panelTitle="Total Jiu Jitsu Career">
                    <StatBox style={{backgroundColor: "#F5F5F5", color: "#0066CC"}}>
                        <h1>Years of logging</h1>
                        <h2>{this.state.yearsOfTraining}</h2>
                    </StatBox>
                    <StatBox style={{backgroundColor: "#707070", color: "white"}}>
                        <h1>Total training hours</h1>
                        <h2>{this.state.totalHoursTrained}</h2>
                    </StatBox>
                    <StatBox style={{backgroundColor: "#F5F5F5", color: "#0066CC"}}>
                        <h1>Total training sessions</h1>
                        <h2>{this.state.totalTrainingSessions}</h2>
                    </StatBox>
                    <StatBox style={{backgroundColor: "#707070", color: "white"}}>
                        <h1>Longest training break (weeks)</h1>
                        <h2>{this.state.longestTrainingBreak}</h2>
                    </StatBox>
                    <StatBox style={{backgroundColor: "#707070", color: "white"}}>
                        <h1>Average training sessions/week</h1>
                        <h2>{this.state.avgTrainingSessionsPerWeek}</h2>
                    </StatBox>
                    <StatBox style={{backgroundColor: "#F5F5F5", color: "#0066CC"}}>
                        <h1>Average training hours/week</h1>
                        <h2>{this.state.avgTrainingHoursPerWeek}</h2>
                    </StatBox>
                    <StatBox style={{backgroundColor: "#707070", color: "white"}}>
                        <h1>Average training hours/month</h1>
                        <h2>{this.state.avgTrainingHoursPerMonth}</h2>
                    </StatBox>
                    <StatBox style={{backgroundColor: "#F5F5F5", color: "#0066CC"}}>
                        <h1>Average training hours/year</h1>
                        <h2>{this.state.avgTrainingHoursPerYear}</h2>
                    </StatBox>
                </ContentPanel>
            </div>
        )
    }
}

export const TotalCareerPanel = withFirebase(TotalCareerPanelBase);

export const formatDate = (date, separator) => {
    let [month, day, year] = date.toLocaleDateString().split("/");

    if (month.length === 1) {
        month = "0" + month;
    }

    if (day.length === 1) {
        day = "0" + day;
    }

    if (! separator) {
        separator = "/";
    }

    return year + separator + month + separator + day;
}

export const formatTime = (date) => {
    let [hour, minute] = date.toTimeString().split(":");

    if (hour.length === 1) {
        hour = "0" + hour;
    }

    if (minute.length === 1) {
        minute = "0" + minute;
    }

    return hour + ":" + minute;
}

export const formatDuration = (duration) => {
    let {hour, minute} = formatDurationToComponents(duration);
    return (hour + (minute / 60)).toFixed(2);
}

export const formatDurationToComponents = (duration) => {
    const hour = parseInt(duration / (60 * 60));
    const minute = (duration - (hour * 60 * 60))/ 60;

    return ({hour, minute});
}
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


import { AuthUserContext, withAuthentication } from "../Session/Session";
import { SideBar } from "../../components/SideBar/SideBar";
import { Header } from "../../components/Header/Header";
import { HeaderStats } from "../../components/HeaderStats/HeaderStats";
import { Footer } from "../../components/Footer/Footer";

import "./Page.css";

class PageBase extends Component {
    constructor(props) {
        super(props);

        this.state = { isMenuOpen: false };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    toggleMenu = () => {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    }

    onMouseLeave = () => {
        this.setState({isMenuOpen: false});
    }

    onClick = () => {
        this.setState({isMenuOpen: false});
    }

    render() {
        return (
            <AuthUserContext.Consumer>
            { authUser => (
                !authUser ? <div /> :
                <div id="page-menu-wrapper">
                    <SideBar isOpen={this.state.isMenuOpen} toggleMenu={this.toggleMenu} onClick={this.onClick} onMouseLeave={this.onMouseLeave} pageWrapId="page-menu-wrapper" outerContainerId="page" />

                    <div id="page" className={this.state.isMenuOpen ? "menu-open" : null}>
                        <Header toggleMenu={this.toggleMenu}/>
                            <div id="content">
                                <div id="page-body">
                                    <HeaderStats authUser={authUser}/>
                                    {this.props.children}
                                </div>
                            </div>
                        <Footer />
                    </div>
                </div>
            )}
            </AuthUserContext.Consumer>
        )
    }
}

export const Page = withRouter(withAuthentication(PageBase));
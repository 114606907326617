import React from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-firestore'

const config = {
	apiKey: process.env.REACT_APP_DEV_API_KEY,
	authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
	projectId: process.env.REACT_APP_DEV_PROJECT_ID,
	storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_APP_ID
};

class Firebase {
	constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();

        this.db.enablePersistence()
        .then( () => {
            console.log("Persistence enabled");
        })
        .catch( (error) => {
            console.log("Error", error);
        })
    }
    
    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () =>
        this.auth.signOut();

    doPasswordReset = (email) =>
        this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = (password) =>
        this.auth.currentUser.updatePassword(password);

    doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification({
        url: process.env.REACT_APP_DEV_CONFIRMATION_EMAIL_REDIRECT
    });

    doUpdateProfile = (props) => this.auth.currentUser.updateProfile(props);

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) => (
        this.auth.onAuthStateChanged( authUser => {
            if (!authUser) {
                fallback();
            }
            else {
                this.user(authUser.uid).get()
                .then(doc => {
                    let accountCreationDate = new Date(authUser.metadata.creationTime);

                    let emailVerifiedNeedsUpdated = false;
                    if (doc.exists) {
                        let dbUser = doc.data();

                        if (dbUser.verified !== this.auth.currentUser.emailVerified) {
                            dbUser.verified = this.auth.currentUser.emailVerified;
                            emailVerifiedNeedsUpdated = true;
                        }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            providerData: authUser.providerData,
                            accountCreationDate: accountCreationDate,
                            ...dbUser
                        }                        
                    }

                    next(authUser);

                    return emailVerifiedNeedsUpdated;
                })
                .then( (emailVerifiedNeedsUpdated) => {
                    if (emailVerifiedNeedsUpdated) {
                        this.user(authUser.uid).update({verified: this.auth.currentUser.emailVerified});
                    }
                })
            }
        })
    )

    // *** User API ***

    user = (uid) => this.db.doc(`users/${uid}`);

    // training = (uid) => this.db.doc(`users/${uid}`).collection('training');
    // training = (uid) => this.db.doc('users/${uid}/training')

    // users = (uid) => this.db.ref('users');

    // *** Message API ***

    // message = (uid) => this.db.ref(`messages/${uid}`);

    // messages = () => this.db.ref('messages');
}

const FirebaseContext = React.createContext(null);

const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
)

export { Firebase, FirebaseContext, withFirebase };
import React from 'react';
import RetinaImage from 'react-retina-image';

import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import "./Header.css";

// import logo from "../../images/bjj-logbook-title.png";
// import logo2x from "../../images/bjj-logbook-title@2x.png";

import menu from "../../images/hamburger-menu.png";
import menu2x from "../../images/hamburger-menu@2x.png";

export const Header = ({toggleMenu, children}) => {
    return (
        <div id="header">
            <div className="button-area" />
            <div id="logo-area">
                <Link to={ROUTES.HOME}>
                    <div id="logo">
                        <div className="white" />
                        <div id="title">BJJ Logbook</div>
                        {/* Todo: Try changing this back to an image and resize it using auto */}
                        {/* <RetinaImage src={[logo, logo2x]} id="Logo" /> */}
                        <div className="white" />
                    </div>
                </Link>
            </div>
            <div id="right" className="button-area">
                <button id="menu-button" onClick={toggleMenu}>
                    <RetinaImage src={[menu, menu2x]} alt="Menu" />
                </button>
            </div>
        </div>
    )
}

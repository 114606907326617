import React, { Component } from 'react';

import { AuthUserContext } from '../../components/Session/Session';
import { Page } from '../../components/Page/Page';
import { ContentCarousel } from '../../components/ContentCarousel/ContentCarousel';

import { AccountDetailsPanel } from '../../pages/AccountDetails/AccountDetails';
import { UpdatePasswordPanel } from '../../pages/UpdatePassword/UpdatePassword';

import "./Settings.css";

export class Settings extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                    { authUser => (
                        <div id="settings">
                            <ContentCarousel location={this.props.location}>
                                <div id="panel"><AccountDetailsPanel authUser={authUser}/></div>
                                <div id="panel"><UpdatePasswordPanel authUser={authUser}/></div>
                            </ContentCarousel>
                        </div>
                    )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}
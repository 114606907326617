import React, { Component } from 'react';
import RetinaImage from 'react-retina-image';

import { withAuthentication, AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { StatBox } from "../../components/StatBox/StatBox"

import podium from '../../images/podium.png';
import podium2x from '../../images/podium@2x.png';

import { Page } from '../../components/Page/Page';
import "./CompetitionCareer.css";
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';

import stats74 from '../../images/stats-74.png';
import stats742x from '../../images/stats-74@2x.png';

class CompetitionCareerBase extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                { authUser => (
                    <CompetitionCareerPanel authUser={authUser} />
                )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}

export const CompetitionCareer = withAuthentication(CompetitionCareerBase);

class CompetitionCareerPanelBase extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    get initialState() {
        return {
            goldMedals: 0,
            silverMedals: 0,
            bronzeMedals: 0,
            numberOfCompetitions: 0,
            numberOfMedals: 0,
            numberOfNoGiMatches: 0,
            numberOfGiMatches: 0,
        };
    }

    componentDidMount() {
        let uid = this.props.authUser.uid;
        this.props.firebase.user(uid).collection("competition").orderBy("date", "desc").get().then( (snapshot) => {
            let stats = this.initialState;
            snapshot.docs.forEach( (doc) => {
                let data = doc.data();

                stats["goldMedals"] += data["awardedMedal"] === "Gold" ? 1 : 0;
                stats["silverMedals"] += data["awardedMedal"] === "Silver" ? 1 : 0;
                stats["bronzeMedals"] += data["awardedMedal"] === "Bronze" ? 1 : 0;
                stats["numberOfCompetitions"] += 1;
                stats["numberOfMedals"] += 1;
                stats["numberOfGiMatches"] += data["giOrNoGi"] === "Gi" ? data["numberOfMatches"] : 0;
                stats["numberOfNoGiMatches"] += data["giOrNoGi"] === "NoGi" ? data["numberOfMatches"] : 0;
            });

            this.setState(stats);
        })
        .catch( (error) => {
            console.log("Error ", error);
        })
    }

    render() {
        return (
            <ContentPanel images={[stats74, stats742x]} alt="Stats" panelTitle="Competition Career">
                <PodiumBox />
                <StatBox style={{ backgroundColor: "#FFD700", color: "white" }}>
                    <h1>Gold Medals</h1>
                    <h2>{this.state["goldMedals"]}</h2>
                </StatBox>
                <StatBox style={{ backgroundColor: "#C8C8C8", color: "black" }}>
                    <h1>Silver Medals</h1>
                    <h2>{this.state["silverMedals"]}</h2>
                </StatBox>
                <StatBox style={{ backgroundColor: "#CD7F32", color: "white" }}>
                    <h1>Bronze Medals</h1>
                    <h2>{this.state["bronzeMedals"]}</h2>
                </StatBox>
                <StatBox style={{ backgroundColor: "#F5F5F5", color: "#0066CC" }}>
                    <h1># Competitions</h1>
                    <h2>{this.state["numberOfCompetitions"]}</h2>
                </StatBox>
                <StatBox style={{ backgroundColor: "#F5F5F5", color: "#0066CC" }}>
                    <h1># Medals</h1>
                    <h2>{this.state["numberOfMedals"]}</h2>
                </StatBox>
                <StatBox style={{ backgroundColor: "#F5F5F5", color: "#0066CC" }}>
                    <h1># Gi Matches</h1>
                    <h2>{this.state["numberOfGiMatches"]}</h2>
                </StatBox>
                <StatBox style={{ backgroundColor: "#F5F5F5", color: "#0066CC" }}>
                    <h1># No Gi Matches</h1>
                    <h2>{this.state["numberOfNoGiMatches"]}</h2>
                </StatBox>
            </ContentPanel>
        )
    }
}

export const CompetitionCareerPanel = withFirebase(CompetitionCareerPanelBase);

const PodiumBox = () => (
    <div id="podium-box" className="stat-box">
        <RetinaImage src={[podium, podium2x]} alt="Podium" />
    </div>
)

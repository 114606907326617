export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgot-password';
export const VERIFY_EMAIL = '/verify-email';
export const SEARCH = "/search";
export const LOGBOOK = "/logbook";
export const TRAINING = '/training';
export const TRAINING_ENTRY = '/training-entry';
export const COMPETITION = '/competition';
export const COMPETITION_ENTRY = '/competition-entry';
export const PROMOTION = "/promotion";
export const PROMOTION_ENTRY = "/promotion-entry";
export const WORLDWIDE = '/worldwide';
export const STATISTICS = "/statistics";
export const TOTAL_CAREER = '/total-career';
export const COMPETITION_CAREER = '/competition-career';
export const BELT_PROGRESS = '/belt-progress';
export const SETTINGS = "/settings";
export const ACCOUNT_DETAILS = '/account-details';
export const UPDATE_PASSWORD = '/update-password';

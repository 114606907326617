import React, { Component } from 'react';

import { AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';

import { Page } from '../../components/Page/Page';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';

import worldwide74 from '../../images/worldwide.png';
import worldwide742x from '../../images/worldwide@2x.png';

import "./Worldwide.css";

export class Worldwide extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                    { authUser => (
                        <WorldwidePanel authUser={authUser} />
                    )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}

class WorldwidePanelBase extends Component {

    get initialState() {
        return {
            logPoints: [0, 0, 0, 0, 0],
            totalTraining: [0, 0, 0, 0, 0],
            totalTrainingGi: [0, 0, 0, 0, 0],
            totalTrainingNoGi: [0, 0, 0, 0, 0],
        };
    }

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    componentDidMount = () => {
        this.props.firebase.db.doc('global/worldwide').get()
        .then( (doc) => {
            let data = doc.data();
            this.setState( data );
            
        })
        .catch( (error) => {
            // TODO: Display error
            console.log(error);
        })
    }

    render() {
        return (
            <div className="world-wide-panel">
                <ContentPanel images={[worldwide74, worldwide742x]} iconTitle="Stats" panelTitle="Worldwide">
                    <div className="line-stat">There are Logbookers logging from over <b>2,000</b> locations and that number keeps growing!</div>
                    <TopFivePanel title="Top 5 Logbook Scores" values={this.state.logPoints}/>
                    <TopFivePanel title="Top 5 Total Hours Trained" values={this.state.totalTraining}/>
                    <TopFivePanel title="Top 5 Total Gi Hours Trained" values={this.state.totalTrainingGi}/>
                    <TopFivePanel title="Top 5 Total No Gi Hours Trained" values={this.state.totalTrainingNoGi}/>
                </ContentPanel>
            </div>
        )
    }
}

export const TopFivePanel = ({title, values}) => {
    let items = [];

    for (let i in values) {
        let value = values[i];
        items.push(<span className="top-five-row" key={i}>
            <div className="number">{Number(i) + 1}.</div>
            <div className="value">{value.toFixed(0)}</div>
        </span>);
    }

    return (
        <div className="top-five-panel">
            <div className="title">{title}</div>
            <div className="content">
                {items}
            </div>
        </div>
    )
}

export const WorldwidePanel = withFirebase(WorldwidePanelBase);
import "./SideBar.css";

import React from 'react';
import RetinaImage from 'react-retina-image';

import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase/Firebase';
import { slide as Menu } from 'react-burger-menu';
import { withSizes } from 'react-sizes';

import * as ROUTES from '../../constants/routes';

import close from '../../images/close-button.png';
import close2x from '../../images/close-button@2x.png';
import search from '../../images/search-icon.png';
import search2x from '../../images/search-icon@2x.png';
import logbook74 from '../../images/logbook-74.png';
import logbook742x from '../../images/logbook-74@2x.png';
import stats74 from '../../images/stats-74.png';
import stats742x from '../../images/stats-74@2x.png';
import settings74 from '../../images/settings-74.png';
import settings742x from '../../images/settings-74@2x.png';

export class SideBarBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = { search: "" };

        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
    }

    onSearchChange = (event) => {
        this.setState( {[event.target.name]: event.target.value} );
    }

    onSearchSubmit = (event) => {
        event.preventDefault();
        this.props.toggleMenu();
        this.props.history.push(ROUTES.SEARCH, {search: this.state.search});
    }

    render() {
        return (
            <div id="sidebar" onMouseLeave={this.props.onMouseLeave}>
                <Menu right noOverlay disableAutoFocus width={this.props.width} customBurgerIcon={false} customCrossIcon={false} {...this.props}>
                    <div id="close-section" className="section">
                        <button id="close-button" onClick={this.props.onClick}>
                            <RetinaImage src={[close, close2x]} alt="Close" />
                        </button>
                    </div>
                    <div id="search-section" className="section">
                        <form id="search-form" onSubmit={this.onSearchSubmit}>
                            <div><RetinaImage id="search-icon" src={[search, search2x]} alt="Search" /></div>
                            <input type="text" name="search" placeholder="Search Logs" onChange={this.onSearchChange}/>
                        </form>
                    </div>
                    <div className="section">
                        <h1 >View Logbook</h1>
                        <div className="content">
                            <div><RetinaImage src={[logbook74, logbook742x]} alt="Logbook" /></div>
                            <div>
                                <Link id="menu-link" to={{pathname: ROUTES.LOGBOOK, state: { slide: 0 }}} onClick={this.props.onClick} replace>Training</Link>
                                <Link id="menu-link" to={{pathname: ROUTES.LOGBOOK, state: { slide: 1 }}} onClick={this.props.onClick} replace>Competition</Link>
                                <Link id="menu-link" to={{pathname: ROUTES.LOGBOOK, state: { slide: 2 }}} onClick={this.props.onClick} replace>Promotion</Link>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <h1>Your Statistics</h1>
                        <div className="content">
                            <div><RetinaImage src={[stats74, stats742x]} alt="Stats" /></div>
                            <div>
                                <Link id="menu-link" to={{pathname: ROUTES.STATISTICS, state: { slide: 0 }}} onClick={this.props.onClick}>Total Career</Link>
                                <Link id="menu-link" to={{pathname: ROUTES.STATISTICS, state: { slide: 1 }}} onClick={this.props.onClick}>Competition Career</Link>
                                <Link id="menu-link" to={{pathname: ROUTES.STATISTICS, state: { slide: 2 }}} onClick={this.props.onClick}>Belt Progress</Link>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <h1>Settings</h1>
                        <div className="content">
                            <div><RetinaImage src={[settings74, settings742x]} alt="Settings" /></div>
                            <div>
                                <Link id="menu-link" style={{color: "white"}} to={{pathname: ROUTES.SETTINGS, state: { slide: 0 }}} onClick={this.props.onClick}>Account Details</Link>
                                <Link id="menu-link" style={{color: "white"}} to={{pathname: ROUTES.SETTINGS, state: { slide: 1 }}} onClick={this.props.onClick}>Update Password</Link>
                                <a id="menu-link" href="mailto: support@atomiccyan.com">Feedback</a>
                                <SignOutButton />
                            </div>
                        </div>
                    </div>
                </Menu>
            </div>
        )
    }
  }

const mapSizesToProps = ({ width }) => {
    if (width > 500) {
        return ({width: "500px"})
    }
    else {
        return ({width: "100%"})
    }
}

export const SideBar = withRouter(withSizes(mapSizesToProps)(SideBarBase));

const SignOutButtonBase = ({firebase}) => (
    <button type="button" id="menu-link"  onClick={firebase.doSignOut}>
      Logout
    </button>
  )

const SignOutButton = withFirebase(SignOutButtonBase);
import React, { Component } from 'react';

import { AuthUserContext } from '../../components/Session/Session';
import { withFirebase } from '../../components/Firebase/Firebase';
import { SettingsPanel } from '../../components/SettingsPanel/SettingsPanel';
import { Page } from '../../components/Page/Page';
import { Status } from '../../components/Status/Status';

import "./AccountDetails.css";

export class AccountDetails extends Component {
    render() {
        return (
            <Page>
                <AuthUserContext.Consumer>
                { authUser => (
                    <AccountDetailsPanel authUser={authUser} />
                )}
                </AuthUserContext.Consumer>
            </Page>
        )
    }
}

class AccountDetailsPanelBase extends Component {

    get initialState() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            verifyEmail: "",
            error: null,
            success: null
        }
    }

    constructor(props) {
        super(props);

        this.state = this.initialState;
        this.unsubscribe = null;

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {

        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }

        this.unsubscribe = this.props.firebase.user(this.props.authUser.uid).onSnapshot( (doc) => {
            let data = doc.data();
            this.setState({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                verifyEmail: data.email,
                error: null,
                success: null,
            })
        })
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    }

    onChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value, status: null, error: null});
    }

    onSubmit = (event) => {
        event.preventDefault();

        // Verify email and verify email match
        if (this.state.email !== this.state.verifyEmail) {
            this.setState({error: "Email addresses do not match"});
            return;
        }

        // TODO: Make this conditional, so if nothing has changed, nothing gets updated

        let user = this.props.firebase.auth.currentUser;
        let emailChanged = (this.state.email !== user.email);

        this.props.firebase.user(this.props.authUser.uid).update({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
        })
        .then( () => {
            return user.updateProfile({displayName: this.state.firstName + " " + this.state.lastName});
        })
        .then( () => {
            if (emailChanged) {
                return user.updateEmail(this.state.email);
            }
        })
        .then( () => {
            if (emailChanged) {
                return this.props.firebase.user(this.props.authUser.uid).update({
                    email: this.state.email,
                })        
            }
        })
        .then( () => {
            if (!user.emailVerified) {
                return user.sendEmailVerification();
            }
        })
        .then( () => {
            this.setState({success: "Account information updated"});
        })
        .catch( (error) => {
            this.setState({error: error.message});
        });
    }

    render() {
        return (
            <div id="account-details-panel">
                <SettingsPanel title="Account Details">
                    <form onSubmit={this.onSubmit}>
                        <div>
                            <label>First name</label>
                            <input name="firstName" type="text" value={this.state.firstName} onChange={this.onChange} required/>
                        </div>

                        <div>
                            <label>Last name</label>
                            <input  name="lastName" type="text" value={this.state.lastName} onChange={this.onChange} required/>
                        </div>

                        <div>
                            <label>Email address</label>
                            <input name="email" type="text" value={this.state.email} onChange={this.onChange} required/>
                        </div>

                        <div>
                            <label>Verify email address</label>
                            <input  name="verifyEmail" type="text" value={this.state.verifyEmail} onChange={this.onChange} required/>
                        </div>

                        <div id="buttons">
                            <button>Save</button>
                            <button>Cancel</button>
                        </div>

                        <Status error={this.state.error} success={this.state.success} />
                    </form>
                </SettingsPanel>
            </div>
        )
    }
}

export const AccountDetailsPanel = withFirebase(AccountDetailsPanelBase);
import React, { Component } from 'react';
import Carousel from 'nuka-carousel';
import RetinaImage from 'react-retina-image';
import { withSize } from 'react-sizeme';

import "./ResponsiveCarousel.css";

import leftArrow from '../../images/left-arrow.png';
import leftArrow2x from '../../images/left-arrow@2x.png';
import rightArrow from '../../images/right-arrow.png';
import rightArrow2x from '../../images/right-arrow@2x.png';
import slideVisible from '../../images/tile-indicator-visible.png';
import slideVisible2x from '../../images/tile-indicator-visible@2x.png';
import slideInvisible from '../../images/tile-indicator-invisible.png';
import slideInvisible2x from '../../images/tile-indicator-invisible@2x.png';


class ResponsiveCarouselBase extends Component {
    constructor(props) {
        super(props);

        this.state = {slideIndex: 0};

        this.totalSlides = this.totalSlides.bind(this);
        this.afterSlide = this.afterSlide.bind(this);
        this.nextButtonPressed = this.nextButtonPressed.bind(this);
        this.backButtonPressed = this.backButtonPressed.bind(this);
        this.slidesToShow = this.slidesToShow.bind(this);
    }

    totalSlides() {
        let count = 0;
        React.Children.forEach(this.props.children, (child) => {
            count++;
        });
        return count;
    }

    afterSlide(slideIndex) {
        this.setState({slideIndex});
    }

    nextButtonPressed = () => {
        let slidesToShow = this.slidesToShow();

        if (this.state.slideIndex < (this.totalSlides() - slidesToShow)) {
            let newSlideIndex = this.state.slideIndex + slidesToShow;
            if (newSlideIndex > this.totalSlides() - 1) {
                newSlideIndex = this.totalSlides() -  1;
            }
            this.setState({slideIndex: newSlideIndex});
        }
    }

    backButtonPressed = () => {
        let slidesToShow = this.slidesToShow();

        if (this.state.slideIndex > 0) {
            let newSlideIndex = this.state.slideIndex - slidesToShow;
            if (newSlideIndex < 0) {
                newSlideIndex = 0;
            }
            this.setState({slideIndex: newSlideIndex});
        }
    }

    slidesToShow = () => {
        let size = this.props.size;
        let slidesToShow = 1;

        if (size.width < 700) {
            slidesToShow = 1;
        }
        else if (size.width < 1000) {
            slidesToShow = 2;
        }
        else if (size.width < 1250) {
            slidesToShow = 3;
        }
        else {
            slidesToShow = 4;
        }
        return slidesToShow;
    }

    render() {

        let slidesToShow = this.slidesToShow();
        let firstSlide = this.state.slideIndex;

        if (firstSlide + slidesToShow > this.totalSlides()) {
            firstSlide = this.totalSlides() - slidesToShow;
        }

        return (
            <div id="responsive-carousel">
                <Carousel framePadding="0px" slideIndex={firstSlide} withoutControls={true} slidesToShow={slidesToShow} slidesToScroll={slidesToShow} wrapAround={false} afterSlide={this.afterSlide}>
                    {this.props.children}
                </Carousel>
                <CarouselControl totalSlides={this.totalSlides()} slidesToShow={slidesToShow} slideIndex={firstSlide} nextButtonPressed={this.nextButtonPressed} backButtonPressed={this.backButtonPressed}/>
            </div>
        )
    }
}
export const ResponsiveCarousel = withSize()(ResponsiveCarouselBase);

export const CarouselControl = ({totalSlides, slidesToShow, slideIndex, nextButtonPressed, backButtonPressed}) => {
    let indicators = []
    for (let i = 0; i < totalSlides; i++) {
        if (i >= slideIndex && i < slideIndex + slidesToShow) {
            indicators.push(<RetinaImage key={i} src={[slideVisible, slideVisible2x]} alt="Slide Visible" />);
        }
        else {
            indicators.push(<RetinaImage key={i} src={[slideInvisible, slideInvisible2x]} alt="Slide Not Visible" />);
        }
    }

    return (
        <div id="responsive-carousel-control">
            <button id="back" onClick={backButtonPressed}><RetinaImage src={[leftArrow, leftArrow2x]} alt="Left Arrow" /></button>
                {indicators}
            <button id="next" onClick={nextButtonPressed}><RetinaImage src={[rightArrow, rightArrow2x]} alt="Right Arrow" /></button>
        </div>
    )
}
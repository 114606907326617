import React from 'react';

import './Status.css';

export const Status = ({error, success}) => {

    if (error) {
        return <div className="status error">{error}</div>;
    }
    else if (success) {
        return <div className="status success">{success}</div>;
    }
    else {
        return <div className="status none" />;
    }
}
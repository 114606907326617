import React from 'react';
import { withFirebase } from "../Firebase/Firebase";
import * as ROUTES from '../../constants/routes';

const AuthUserContext = React.createContext(null);

const withAuthentication = (Component) => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = { authUser: null };
        }

        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                authUser => {
                    if (authUser && authUser.verified) {
                        this.setState({ authUser });
                    }
                    else if (authUser && !authUser.verified) {
                        this.setState({ authUser: null });
                        this.props.history.push(ROUTES.VERIFY_EMAIL); 
                    }
                    else {
                        this.setState({authUser: null });
                        this.props.history.push(ROUTES.LOGIN); 
                    }
                },
                () => {;
                    this.setState({authUser: null });
                    this.props.history.push(ROUTES.LOGIN); 
                }
            )
        }

        componentWillUnmount() {
            if (this.listener) {
                this.listener();
                this.listener = null;
            }
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    {/* TODO: Consider passing authUser as a prop to Component */}
                    <Component {...this.props}/>
                </AuthUserContext.Provider>
            );
        }
    }

    return withFirebase(WithAuthentication);
}

export { AuthUserContext, withAuthentication }
import React, { Component } from 'react';
import RetinaImage from 'react-retina-image';

import * as ROUTES from '../../constants/routes';
import { ContentPanel } from '../../components/ContentPanel/ContentPanel';
import { ResponsiveCarousel } from '../../components/ResponsiveCarousel/ResponsiveCarousel';
import { Page } from '../../components/Page/Page';

import logbook from '../../images/logbook.png';
import logbook2x from '../../images/logbook@2x.png';
import worldwide from '../../images/worldwide.png';
import worldwide2x from '../../images/worldwide@2x.png';
import stats from '../../images/stats.png';
import stats2x from '../../images/stats@2x.png';
import settings from '../../images/settings.png';
import settings2x from '../../images/settings@2x.png';

import './Home.css';

class Home extends Component {

    render() {

        return (
            <div id="home">
                <Page>
                    <ContentPanel hideBorder={true}>
                        <ResponsiveCarousel>
                            <HomePagePanel images={[logbook, logbook2x]} title="Your Logbook" buttonTitle="Open the Logbook" onClick={(event) => {this.props.history.push(ROUTES.LOGBOOK)}}>
                                Access your logbook now to add a log or review old logs.
                            </HomePagePanel>
                            <HomePagePanel images={[worldwide, worldwide2x]} title="Worldwide" buttonTitle="The BJJ world" onClick={(event) => {this.props.history.push(ROUTES.WORLDWIDE)}}>
                                Logbookers are scattered all over the world - in 50 countries and counting...
                            </HomePagePanel>
                            <HomePagePanel images={[stats, stats2x]} title="Your Statistics" buttonTitle="See your stats" onClick={(event) => {this.props.history.push(ROUTES.STATISTICS)}}>
                                You've been training hard. Let's break down your training by the numbers.
                            </HomePagePanel>
                            <HomePagePanel images={[settings, settings2x]} title="Settings" buttonTitle="Settings" onClick={(event) => {this.props.history.push(ROUTES.SETTINGS)}}>
                                Let's get everything set up for success.
                            </HomePagePanel>
                        </ResponsiveCarousel>
                    </ContentPanel>
                </Page>
            </div>
        )
    }
}

const HomePagePanel = ({images, title, buttonTitle, children, onClick}) => (
    <div id="home-page-panel" className="drop-shadow corner-radius">
        <RetinaImage src={images} alt={title} />
        <div className="title">
            <span>{title}</span>
        </div>
        <div className="text">
            {children}
        </div>
        <button type="button" onClick={onClick}>{buttonTitle}</button>
    </div>
)

export { Home };
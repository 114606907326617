import React from 'react';

import "./Belt.css";

// https://stackoverflow.com/questions/2941189/how-to-overlay-one-div-over-another-div

// export const Belt = ({ beltColor, style, years, rankColor, stripeCount, textColor, kidStripeColor }) => {
export const Belt = ({ beltColor, beltStripes, beltYears }) => {

    let beltColorToRgb = {
        White: "white",
        Grey: "grey",
        Yellow: "yellow",
        Orange: "orange",
        Green: "green",
        Blue: "#0033CC",
        Purple: "#663399",
        Brown: "#5A2D0C",
        Black: "black",
    };

    let beltColorComponents = beltColor.split("/");
    let beltBackgroundColor = beltColorToRgb[beltColorComponents[0]];
    let kidStripeColor = beltColorComponents.length > 1 ? beltColorComponents[1] : beltBackgroundColor;


    let textColor = (beltColor === "White" || kidStripeColor === "White") ? "black" : "white";
    let rankColor = (beltColor === "Black") ? "red" : "black";

    return (
        <div id="belt" style={{backgroundColor: beltBackgroundColor}}>
            <div id="background">
                <div id="decorations">
                    { beltYears ? <div id="belt-text" style={{ color: textColor}}>{beltYears} years</div> : <div id="belt-text" /> }
                    <Rank style={{ backgroundColor: "black" }} stripeCount={beltStripes} color={rankColor} />
                </div>

                { kidStripeColor ? <div id="kid-stripe" style={{backgroundColor: kidStripeColor}}/> : <div />}
            </div>
        </div>
    )
}

const Rank = ({ style, stripeCount, color }) => {

    let stripes = []
    for (let i = 0; i < stripeCount; i++) {
        stripes.push(<Stripe key={i}/>);
    }

    return (
        <div id="rank" style={{ backgroundColor: color }}>
            {stripes}
        </div>
    );
}

const Stripe = () => (
    <div id="stripe">
    </div>
)
